/* JWT */
export const LOGOUT = "LOGOUT";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const STORE_JWT_TOKEN = "STORE_JWT_TOKEN";
export const REMOVE_JWT_TOKEN = "REMOVE_JWT_TOKEN";
export const APPS_URL = "https://apps.jelou.ai";

/* Errors */
export const ADD_ERRORS = "ADD_ERRORS";
export const ADD_SAVED_ERRORS = "ADD_SAVED_ERRORS";

export const SET_IMPERSONATE = "SET_IMPERSONATE";
export const SET_RETURNING_TO_APPS = "SET_RETURNING_TO_APPS";

export const IS_SAVING_CHANGES = "IS_SAVING_CHANGES";
export const IS_NOT_SAVING_CHANGES = "IS_NOT_SAVING_CHANGES";

export const SET_BOT_TESTER = "SET_BOT_TESTER";

export const SET_UNATHORIZATION = "SET_UNATHORIZATION";

/* COMPANIES */
export const ADD_COMPANIES = "ADD_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";

/* BOTS */
export const UNSET_BOT = "UNSET_BOT";

/* BOT */
export const SET_BOT = "SET_BOT";

/* FLOWS */
export const ADD_FLOWS = "ADD_FLOWS";
export const GET_FLOWS = "GET_FLOWS";
export const REMOVE_FLOWS = "ADD_FLOWS";
export const ADD_TO_FLOWS = "ADD_TO_FLOWS";
export const DELETE_FROM_FLOWS = "DELETE_FROM_FLOWS";
export const UPDATE_FROM_FLOWS = "UPDATE_FROM_FLOWS";
export const DELETE_FLOWS = "DELETE_FLOWS";

/* FLOW */
export const ADD_FLOW = "ADD_FLOW";
export const GET_FLOW = "GET_FLOW";
export const DELETE_FLOW = "DELETE_FLOW";
export const UPDATE_FLOW = "UPDATE_FLOW";
export const TOGGLE_ADDING_FLOW = "TOGGLE_ADDING_FLOW";
export const SET_DEFAULT_FLOW = "SET_DEFAULT_FLOW";

/* INTENTS */
export const ADD_INTENT = "ADD_INTENT";
export const ADD_CURRENT_INTENT = "ADD_CURRENT_INTENT";
export const UPDATE_CURRENT_INTENT = "UPDATE_CURRENT_INTENT";
export const ADD_INTENTS = "ADD_INTENTS";
export const UPDATE_INTENTS = "UPDATE_INTENTS";
export const DELETE_INTENT = "DELETE_INTENT";
export const UPDATE_INTENT = "UPDATE_INTENT";
export const TOGGLE_ADDING_INTENT = "TOGGLE_ADDING_INTENT";
export const DELETE_INTENTS = "DELETE_INTENTS";
export const LOADING_INTENTS = "LOADING_INTENTS";

/* BUBBLES */
export const BubbleTypes = {
    TEXT: "text",
    IMAGE: "image",
    INPUT: "input",
    RANDOM: "random",
    GENERIC: "generic",
    RESOURCE: "resource",
    CONDITIONAL: "conditional",
    QUICK_REPLY: "quick_reply",
    CONTACT_BUBBLE: "contact_bubble",
    SANDBOX: "sandbox",
    VIDEO: "video",
    FILE: "file",
    MARKER: "marker",
    CHECKPOINT: "checkpoint",
    PMA: "pma",
    AUDIO: "audio",
    STICKER: "sticker",
    TOOLKIT: "tool",
    SKILL: "skill",
    RUN_ACTION: "run_action",
};
export const ADD_BUBBLE = "ADD_BUBBLE";
export const ADD_BUBBLES = "ADD_BUBBLES";
export const DELETE_BUBBLE = "DELETE_BUBBLE";
export const UPDATE_BUBBLE = "UPDATE_BUBBLE";
export const MERGE_BUBBLES = "MERGE_BUBBLES";
export const DELETE_BUBBLES = "DELETE_BUBBLES";
export const UPDATE_BUBBLES = "UPDATE_BUBBLES";
export const REPLACE_BUBBLES = "REPLACE_BUBBLES";
export const ADD_BUBBLE_BUTTON = "ADD_BUBBLE_BUTTON";
export const UPDATE_BUBBLE_TEXT = "UPDATE_BUBBLE_TEXT";
export const UPDATE_BUBBLE_BUTTON = "UPDATE_BUBBLE_BUTTON";
export const DELETE_ALL_BUBBLES = "DELETE_ALL_BUBBLES";

/* RESOURCES */
export const ADD_RESOURCE = "ADD_RESOURCE";
export const ADD_RESOURCES = "ADD_RESOURCES";
export const ADD_RESOURCES_LIGTH = "ADD_RESOURCES_LIGTH";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const ADDING_RESOURCE = "ADDING_RESOURCE";
export const SAVING_RESOURCE = "SAVING_RESOURCE";
export const CREATING_RESOURCE = "CREATING_RESOURCE";
export const RESET_RESOURCE_STATE = "RESET_RESOURCE_STATE";
export const TOGGLE_ADDING_RESOURCE = "TOGGLE_ADDING_RESOURCE";
export const ResourceMethods = [
    {
        name: "method",
        label: "GET",
        value: "get",
    },
    {
        name: "method",
        label: "POST",
        value: "post",
    },
    {
        name: "method",
        label: "PUT",
        value: "put",
    },
    {
        name: "method",
        label: "PATCH",
        value: "patch",
    },
    {
        name: "method",
        label: "DELETE",
        value: "delete",
    },
];
export const ResourceTypes = [
    {
        input: "select",
        name: "type",
        label: "SOAP",
        value: "soap",
    },
    {
        input: "select",
        name: "type",
        label: "HTTP",
        value: "http",
    },
];
export const ResourceTemplate = {
    data: [
        {
            key: "string",
            type: "string",
            value: "string",
        },
    ],
    params: ["string"],
    responsePath: "string",
    storeAs: "string",
    storeErrorAs: "string",
    version: 2,
};

/* OPTIONS */
export const OptionTypes = {
    URL: "url",
    FLOW: "postback",
    PHONE: "phone_number",
};
export const ADD_OPTION = "ADD_OPTION";
export const ADD_OPTIONS = "ADD_OPTIONS";
export const UPDATE_OPTION = "UPDATE_OPTION";
export const DELETE_OPTION = "DELETE_OPTION";
export const DELETE_OPTIONS = "DELETE_OPTIONS";
export const UPDATE_OPTIONS = "UPDATE_OPTIONS";
export const DELETE_ALL_OPTIONS = "DELETE_ALL_OPTIONS";

/* ELEMENTS */
export const ADD_ELEMENT = "ADD_ELEMENT";
export const ADD_ELEMENTS = "ADD_ELEMENTS";
export const UPDATE_ELEMENT = "UPDATE_ELEMENT";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const DELETE_ELEMENTS = "DELETE_ELEMENTS";
export const UPDATE_ELEMENTS = "UPDATE_ELEMENTS";

/* RANDOMS */
export const ADD_RANDOM = "ADD_RANDOM";
export const ADD_RANDOMS = "ADD_RANDOMS";
export const UPDATE_RANDOM = "UPDATE_RANDOM";
export const DELETE_RANDOM = "DELETE_RANDOM";
export const DELETE_RANDOMS = "DELETE_RANDOMS";
export const UPDATE_RANDOMS = "UPDATE_RANDOMS";
export const DELETE_ALL_RANDOMS = "DELETE_ALL_RANDOMS";

/* ATTACHMENTS */
export const AttachmentTypes = {
    FILE: "file",
    IMAGE: "image",
    AUDIO: "audio",
    VIDEO: "video",
    STICKER: "sticker",
};
export const ADD_ATTACHMENT = "ADD_ATTACHMENT";
export const UPDATE_ATTACHMENT = "UPDATE_ATTACHMENT";
export const UPDATE_ATTACHMENTS = "UPDATE_ATTACHMENTS";
export const DELETE_ALL_ATTACHMENTS = "DELETE_ALL_ATTACHMENTS";

/* INPUTS */
export const ADD_INPUT = "ADD_INPUT";
export const ADD_INPUTS = "ADD_INPUTS";
export const UPDATE_INPUT = "UPDATE_INPUT";
export const UPDATE_INPUTS = "UPDATE_INPUTS";
export const DELETE_ALL_INPUTS = "DELETE_ALL_INPUTS";

/* SANDBOXES */
export const ADD_SANDBOX = "ADD_SANDBOX";
export const ADD_SANDBOXES = "ADD_SANDBOXES";
export const UPDATE_SANDBOX = "UPDATE_SANDBOX";
export const UPDATE_SANDBOXES = "UPDATE_SANDBOXES";
export const DELETE_ALL_SANDBOXES = "DELETE_ALL_SANDBOXES";
export const SANDOX_ACTIONS = {
    REWRITE: "REWRITE",
    MERGE: "MERGE",
};

/* INPUTS */
export const ADD_QUICK_REPLY = "ADD_QUICK_REPLY";
export const ADD_QUICK_REPLIES = "ADD_QUICK_REPLIES";
export const UPDATE_QUICK_REPLY = "UPDATE_QUICK_REPLY";
export const UPDATE_QUICK_REPLIES = "UPDATE_QUICK_REPLIES";
export const DELETE_QUICK_REPLIES = "DELETE_QUICK_REPLIES";
export const DELETE_ALL_QUICK_REPLIES = "DELETE_ALL_QUICK_REPLIES";

/* CONDITIONALS */
export const ADD_CONDITIONAL = "ADD_CONDITIONAL";
export const ADD_CONDITIONALS = "ADD_CONDITIONALS";
export const UPDATE_CONDITIONAL = "UPDATE_CONDITIONAL";
export const UPDATE_CONDITIONALS = "UPDATE_CONDITIONALS";
export const DELETE_ALL_CONDITIONALS = "DELETE_ALL_CONDITIONALS";

/* ACTIONS */
export const NEW = "NEW";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

/* BUBBLE RESOURCES */
export const ADD_BUBBLE_RESOURCE = "ADD_BUBBLE_RESOURCE";
export const ADD_BUBBLE_RESOURCES = "ADD_BUBBLES_RESOURCES";
export const UPDATE_BUBBLE_RESOURCE = "UPDATE_BUBBLE_RESOURCE";
export const UPDATE_BUBBLE_RESOURCES = "UPDATE_BUBBLES_RESOURCES";
export const DELETE_ALL_RESOURCES = "DELETE_ALL_RESOURCES";

/* BUBBLE CONTACT CARD */
export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACTS = "ADD_CONTACTS";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_CONTACTS = "UPDATE_CONTACTS";
export const DELETE_CONTACTS = "DELETE_CONTACTS";
export const DELETE_ALL_CONTACTS = "DELETE_ALL_CONTACTS";

/* UNSAVED CHANGES */
export const SET_UNSAVED_CHANGES = "SET_UNSAVED_CHANGES";
export const UNSET_UNSAVED_CHANGES = "UNSET_UNSAVED_CHANGES";
export const TOGGLE_UNSAVED_CHANGES = "TOGGLE_UNSAVED_CHANGES";

/* EXPRESSIONS */
export const ADD_EXPRESSION = "ADD_EXPRESSION";
export const ADD_EXPRESSIONS = "ADD_EXPRESSIONS";
export const UPDATE_EXPRESSION = "UPDATE_EXPRESSION";
export const DELETE_EXPRESSION = "DELETE_EXPRESSION";

/* EXPRESSION STATE */
export const SAVING_EXPRESSION = "SAVING_EXPRESSION";
export const LOADING_EXPRESSIONS = "LOADING_EXPRESSIONS";
export const SET_EXPRESSION_STATE = "SET_EXPRESSION_STATE";
export const RESET_EXPRESSION_STATE = "RESET_EXPRESSION_STATE";
export const DELETING_EXPRESSION = "DELETING_EXPRESSION";

/* PMA BUBBLE */
export const ADD_PMA_BUBBLES = "ADD_PMA_BUBBLES";
export const ADD_PMA_BUBBLE = "ADD_PMA_BUBBLE";
export const UPDATE_PMA_BUBBLES = "UPDATE_PMA_BUBBLES";
export const DELETE_ALL_PMA_BUBBLES = "DELETE_ALL_PMA_BUBBLES";

/* PMA BUBBLE */
export const ADD_RUN_ACTION_BUBBLES = "ADD_RUN_ACTION_BUBBLES";
export const ADD_RUN_ACTION_BUBBLE = "ADD_RUN_ACTION_BUBBLE";
export const UPDATE_RUN_ACTION_BUBBLES = "UPDATE_RUN_ACTION_BUBBLES";

export const ALL_BUBBLES = [
    "TEXT",
    "RANDOM",
    "IMAGE",
    "VIDEO",
    "FILE",
    "INPUT",
    "GENERIC",
    "RESOURCE",
    "CONDITIONAL",
    "QUICK_REPLY",
    "CONTACT_CARD",
    "SANDBOX",
    "CHECKPOINT",
    "PMA",
    "AUDIO",
    "STICKER",
    "TOOL",
    "SKILL",
    "RUN_ACTION",
];

export const BUBBLES = [
    "FACEBOOK",
    "FACEBOOK_FEED",
    "TWITTER",
    "TWITTER_REPLIES",
    "WIDGET",
    "GOOGLE",
    "ALEXA",
    "VOICEASSISTANCE",
    "INSTAGRAM",
    "WHATSAPP",
    "OFFICIAL_WHATSAPP" /* Smooch ya no trabajamos con eso */,
    "VENOM",
    "GUPSHUP",
    "GUPSHUP_CAPI",
    "WHATSAPP_CLOUD",
    "ALDEAMO",
    "SMOOCH",
    "TWITTER_WS",
];

export const WHATSAPP_CHANNELS = {
    WHATSAPP: "WHATSAPP",
    WHATSAPP_CLOUD: "WHATSAPP_CLOUD",
    VENOM: "VENOM",
    GUPSHUP: "GUPSHUP",
    GUPSHUP_CAPI: "GUPSHUP_CAPI",
    OFFICIAL_WHATSAPP: "OFFICIAL_WHATSAPP",
    ALDEAMO: "ALDEAMO",
};

/* SUPPORTED BUBBLES */
export const SUPPORTED_BUBBLES = {
    FACEBOOK: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: true,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: false,
        SANDBOX: true,
        VIDEO: true,
        FILE: true,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        TOOL: true,
        SKILL: true,
        RUN_ACTION: false,
    },
    WHATSAPP: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: true,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        STICKER: true,
        TOOL: true,
        SKILL: true,
        RUN_ACTION: false,
    },
    OFFICIAL_WHATSAPP: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: true,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        STICKER: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    VENOM: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: false,
        CONTACT_CARD: false,
        SANDBOX: true,
        FILE: false,
        VIDEO: false,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        STICKER: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    GUPSHUP: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: true,
        SANDBOX: true,
        FILE: true,
        VIDEO: true,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        STICKER: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    GUPSHUP_CAPI: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: true,
        SANDBOX: true,
        FILE: true,
        VIDEO: true,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        STICKER: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    WHATSAPP_CLOUD: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: true,
        SANDBOX: true,
        FILE: true,
        VIDEO: true,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        STICKER: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    ALDEAMO: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: true,
        SANDBOX: true,
        FILE: true,
        VIDEO: true,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        STICKER: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    SMOOCH: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: true,
        SANDBOX: true,
        FILE: true,
        VIDEO: true,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        STICKER: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    WIDGET: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: true,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: false,
        CONTACT_CARD: false,
        SANDBOX: true,
        FILE: true,
        CHECKPOINT: true,
        PMA: true,
        AUDIO: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: true,
    },
    TWITTER: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: false,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    TWITTER_WS: {
        TEXT: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RANDOM: true,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: false,
        CONTACT_CARD: false,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        TOOL: true,
        SKILL: true,
        RUN_ACTION: false,
        VIDEO: true,
    },
    GOOGLE: {
        TEXT: true,
        RANDOM: true,
        IMAGE: false,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: false,
        CONTACT_CARD: false,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    ALEXA: {
        TEXT: true,
        RANDOM: true,
        IMAGE: false,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: false,
        CONTACT_CARD: false,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    VOICEASSISTANCE: {
        TEXT: true,
        RANDOM: true,
        IMAGE: false,
        INPUT: true,
        GENERIC: true,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: false,
        CONTACT_CARD: false,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    FACEBOOK_FEED: {
        TEXT: true,
        IMAGE: true,
        INPUT: false,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: false,
        CONTACT_CARD: false,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    TWITTER_REPLIES: {
        TEXT: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: false,
        CONTACT_CARD: false,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        SKILL: true,
        TOOL: true,
        RUN_ACTION: false,
    },
    INSTAGRAM: {
        TEXT: true,
        RANDOM: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: true,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: true,
        CONTACT_CARD: false,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        TOOL: true,
        SKILL: true,
        RUN_ACTION: false,
    },
    DEFAULT: {
        TEXT: true,
        IMAGE: true,
        INPUT: true,
        GENERIC: false,
        RESOURCE: true,
        CONDITIONAL: true,
        QUICK_REPLY: false,
        CONTACT_CARD: false,
        SANDBOX: true,
        CHECKPOINT: true,
        PMA: true,
        TOOL: true,
        SKILL: true,
        RUN_ACTION: false,
    },
};

//text sandbox resource conditional

/* SUPPORTED BUBBLES */
export const MAX_CHARACTERS = {
    FACEBOOK: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    WHATSAPP: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    OFFICIAL_WHATSAPP: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    GUPSHUP: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    GUPSHUP_CAPI: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    WHATSAPP_CLOUD: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    ALDEAMO: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    VENOM: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    SMOOCH: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    WEB: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    WIDGET: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    VOICEASSISTANCE: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
    TWITTER_WS: { TEXT: 499, TITLE: 80, SUBTITLE: 80 },
};
/* MAX BUTTONS (FLOW-URL) */
export const MAX_BUTTONS = {
    FACEBOOK: 3,
    INSTAGRAM: 3,
    OFFICIAL_WHATSAPP: 12,
    GUPSHUP: 12,
    GUPSHUP_CAPI: 12,
    WHATSAPP_CLOUD: 12,
    ALDEAMO: 12,
    VENOM: 12,
    SMOOCH: 12,
    WHATSAPP: 11,
    TWITTER_WS: 10,
    TWITTER: 10,
    WEB: 11,
    WIDGET: 11,
    GOOGLE: 0,
    ALEXA: 0,
    VOICEASSISTANCE: 2,
};

/* MAX CARROUSEL ELEMENTS */
export const MAX_ELEMENTS = {
    FACEBOOK: 10,
    INSTAGRAM: 10,
    WHATSAPP: 0,
    OFFICIAL_WHATSAPP: 0,
    GUPSHUP: 0,
    GUPSHUP_CAPI: 0,
    WHATSAPP_CLOUD: 0,
    ALDEAMO: 0,
    VENOM: 0,
    SMOOCH: 0,
    TWITTER: 0,
    WEB: 10,
    WIDGET: 10,
    VOICEASSISTANCE: 1,
};

/* MAX QUICK_REPLY ELEMENTS */
export const MAX_QR_ELEMENTS = {
    FACEBOOK: 11,
    WHATSAPP: 0,
    OFFICIAL_WHATSAPP: 0,
    GUPSHUP: 3,
    GUPSHUP_CAPI: 3,
    WHATSAPP_CLOUD: 3,
    ALDEAMO: 3,
    VENOM: 0,
    SMOOCH: 3,
    TWITTER: 20,
    WEB: 11,
    WIDGET: 11,
    VOICEASSISTANCE: 0,
    INSTAGRAM: 11,
};

/* BOT MENU SETTINGS MAX NUM */
export const MAX_SETTINGS_BUTTONS = {
    FACEBOOK: 3,
    INSTAGRAM: 3,
    OFFICIAL_WHATSAPP: 3,
    GUPSHUP: 3,
    GUPSHUP_CAPI: 3,
    WHATSAPP_CLOUD: 3,
    ALDEAMO: 3,
    VENOM: 3,
    SMOOCH: 3,
    WHATSAPP: 3,
};

/* IS LOADING */
export const SET_LOADING = "SET_LOADING";
export const UNSET_LOADING = "UNSET_LOADING";

export const SET_LOADING_FLOW = "SET_LOADING_FLOW";
export const UNSET_LOADING_FLOW = "UNSET_LOADING_FLOW";

/* TOAST */
export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

/* VALIDATIONS */
export const VALIDATIONS = {
    /* TEXT CHANNELS */
    WEB: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    WIDGET: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaKey: "required", mediaUrl: "required", type: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    WHATSAPP: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaKey: "required", mediaUrl: "required", mimeType: "required", type: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    OFFICIAL_WHATSAPP: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaKey: "required", mediaUrl: "required", mimeType: "required", type: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    GUPSHUP: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaUrl: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    GUPSHUP_CAPI: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaUrl: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    WHATSAPP_CLOUD: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaUrl: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    ALDEAMO: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaUrl: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    VENOM: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaKey: "required", mediaUrl: "required", mimeType: "required", type: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    SMOOCH: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaKey: "required", mediaUrl: "required", mimeType: "required", type: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    TWITTER: [
        { id: 0, name: "Número", value: "numeric|required" },
        { id: 1, name: "Email", value: "email|required" },
        { id: 2, name: "Moneda", value: "currency|required" },
        { id: 3, name: "Aceptar", value: "accepted" },
        { id: 4, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 5, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 6, name: "Fecha", value: "date|required" },
        { id: 7, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 8, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    TWITTER_WS: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaUrl: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    FACEBOOK: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaKey: "required", mediaUrl: "required", type: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    INSTAGRAM: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Imagen", value: "image", rules: { mediaKey: "required", mediaUrl: "required", type: "required" } },
        { id: 4, name: "Ubicación", value: "location", rules: { lat: "required", lng: "required" } },
        { id: 5, name: "Moneda", value: "currency|required" },
        { id: 6, name: "Aceptar", value: "accepted" },
        { id: 7, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
        { id: 8, name: "Solo letras", value: "alphabetic|max:50|required" },
        { id: 9, name: "Fecha", value: "date|required" },
        { id: 10, name: "Cédula", value: "numeric|min:10|max:10|required" },
        { id: 11, name: "RUC", value: "numeric|min:13|max:13|required" },
    ],
    /* VOICE CHANNELS */
    GOOGLE: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Moneda", value: "currency|required" },
        { id: 4, name: "Aceptar", value: "accepted" },
        { id: 5, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
    ],
    ALEXA: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Moneda", value: "currency|required" },
        { id: 4, name: "Aceptar", value: "accepted" },
        { id: 5, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
    ],
    VOICEASSISTANCE: [
        { id: 0, name: "Requerido", value: "required" },
        { id: 1, name: "Número", value: "numeric|required" },
        { id: 2, name: "Email", value: "email|required" },
        { id: 3, name: "Moneda", value: "currency|required" },
        { id: 4, name: "Aceptar", value: "accepted" },
        { id: 5, name: "ID único", value: "required|numeric|unique_in_channel:{botId},{referenceId}" },
    ],
};

/* VALIDATION MESSAGES */
export const VALIDATION_MESSAGES = {
    required: "Lo que has enviado no es correcto 🙁. Recuerda que debes enviar un texto.",
    numeric: "El texto ingresado es incorrecto 🙁. Recuerda que debes enviar un número.",
    email: "Verifica que tu correo esté correcto y vuelve a intentarlo 📩.",
    image: "Lo que has enviado no es correcto 🙁. Recuerda que debes enviar una imagen.",
    location: "Recuerda que para enviar tu ubicación, debes seleccionar el botón 📍 para poder ayudarte.",
    currency: "La cantidad ingresada es incorrecta 🙁. Recuerda que debes enviar un valor numérico y usar el punto (.) como separador decimal.",
    unique_in_channel: "Esta identificación ya se encuentra vinculada en otro dispositivo.",
    "numeric|required": "El texto ingresado es incorrecto 🙁. Recuerda que debes enviar un número.",
    "email|required": "Verifica que tu correo esté correcto y vuelve a intentarlo 📩.",
    "currency|required":
        "La cantidad ingresada es incorrecta 🙁. Recuerda que debes enviar un valor numérico y usar el punto (.) como separador decimal.",
    "alphabetic|max:50|required": "El texto ingresado es incorrecto 🙁. Recuerda que debes escribir solo letras y hasta un máximo de 50 caracteres.",
    "date|required": "La fecha ingresada es incorrecta 🙁. Recuerda seguir el formato indicado (dd-mm-yyyy).",
    "numeric|min:10|max:10|required": "La cédula ingresada es incorrecta 🙁. Recuerda que debes escribir 10 dígitos.",
    "numeric|min:13|max:13|required": "El RUC ingresado es incorrecto 🙁. Recuerda que debes escribir 13 dígitos.",
};

/* PASSWORD CORRECT FORMAT TO ACTIVATE AN ACCOUNT AND RESET PASSWORD*/
export const PASSWORD_COMPLIANCE = ["Una mayúscula", "Un número", "Mínimo 8 caracteres"];

/* USER */
export const SET_USER = "SET_USER";

/* PERMISSIONS */
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const LOADING_PERMISSIONS = "LOADING_PERMISSIONS";

/* ROLLS */
export const ADD_ROLL = "ADD_ROLL";
export const ADD_ROLLS = "ADD_ROLLS";
export const UPDATE_ROLL = "UPDATE_ROLL";
export const DELETE_ROLL = "DELETE_ROLL";

/* ROLLS STATE */
export const SET_ROLL_STATE = "SET_ROLL_STATE";
export const RESET_ROLL_STATE = "RESET_ROLL_STATE";

/* METRICS */
export const ADD_METRIC_COMPANY_CHANNELS = "ADD_METRIC_COMPANY_CHANNELS";
export const ADD_METRIC_TOP_FLOWS = "ADD_TOP_FLOWS";
export const ADD_METRIC_TOP_INTENTS = "ADD_TOP_INTENTS";
export const ADD_METRIC_UNIQUE_USERS = "ADD_UNIQUE_USERS";
export const ADD_DAILY_SESSIONS = "ADD_DAILY_SESSIONS";
export const ADD_TODAY_SESSIONS = "ADD_TODAY_SESSIONS";
export const ADD_SESSIONS = "ADD_SESSIONS";
export const ADD_METRIC_ACTIVE_USERS = "ADD_ACTIVE_USERS";
export const ADD_METRIC_BOT_ACTIVITY = "ADD_BOT_ACTIVITY";
export const ADD_METRIC_REPORT = "ADD_METRIC_REPORT";
export const FILTER_METRIC = "FILTER_METRIC";
export const UPDATE_BY_INTENT = "UPDATE_BY_INTENT";

/* RESOURCE REPORTS */
export const ADD_RESOURCE_REPORT = "ADD_RESOURCE_REPORT";

/* CONTEXT REPORTS */
export const ADD_CONTEXTS = "ADD_CONTEXTS";
export const ADD_CONTEXT_DETAIL = "ADD_CONTEXT_DETAIL";

/* OPERATORS */
export const ADD_OPERATORS = "ADD_OPERATORS";

/* TRANSACTIONS REPORTS */

/*Cost by session*/
export const COSTO = 0.54;

/* Contexts Options */
export const CONTEXT_TYPES = [
    {
        id: -1,
        name: "All",
    },
    {
        id: 1,
        name: "Completados",
    },
    {
        id: 0,
        name: "No Completados",
    },
];

/*NLP drivers available*/
export const NLP_DRIVERS = [
    {
        id: "none",
        name: "Ninguno",
    },
    {
        id: "recast",
        name: "Recast",
    },
    {
        id: "dialogflow",
        name: "Dialogflow",
    },
    {
        id: "wit",
        name: "Wit",
    },
];

export const MASK_TYPES = [
    {
        id: "REPLACE",
        name: "Reemplazar",
    },
    {
        id: "MASK",
        name: "Enmascarar",
    },
];

export const MAX_VALUE_CARACTER_INTENTS = 280;
