import first from "lodash/first";
import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import toUpper from "lodash/toUpper";
import includes from "lodash/includes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import FormCombobox from "../../common/FormCombobox";
import Checkbox from "../../common/FormComponent/Checkbox";
import Label from "../../common/FormComponent/Label";
import Select from "../../common/FormComponent/Select";
import BubbleInputSettings from "./BubbleInputSettings";
import MaskSettings from "./MaskSettings";

import { updateBubble } from "../../../actions/bubbles";
import { updateInput } from "../../../actions/inputs";
import { updateSandbox } from "../../../actions/sandboxes";

import { BubbleTypes, NEW, SANDOX_ACTIONS, UPDATE } from "../../../constants";
import { BUBBLES_SETTINGS_CHECKBOX_NAMES, CustomTransition, OneTimeUseButtons } from "./OneTimeUseButtons";

const endConversationOptions = [
    {
        id: false,
        name: "No",
        value: "false",
    },
    {
        id: true,
        name: "Yes",
        value: "true",
    },
];

const sandboxOptions = [
    {
        id: SANDOX_ACTIONS.MERGE,
        name: "Merge",
        value: SANDOX_ACTIONS.MERGE,
    },
    {
        id: SANDOX_ACTIONS.REWRITE,
        name: "Rewrite",
        value: SANDOX_ACTIONS.REWRITE,
    },
];

const BubbleSettings = (props) => {
    let { properties = {} } = props.bubble;
    properties = isEmpty(properties) ? {} : properties;

    let inputProperties = props.input ? props.input.properties : {};
    inputProperties = isEmpty(inputProperties) ? {} : inputProperties;

    const type = get(props, "bubble.type");
    const channel = get(props, "channel");
    const sandbox = isEmpty(props.sandbox) ? {} : props.sandbox;

    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedButton, setIsCheckedButton] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const bot = useSelector((state) => state.bot);
    const whatsAppType = ["WHATSAPP", "WHATSAPP_CLOUD", "ALDEAMO", "GUPSHUP", "GUPSHUP_CAPI"];
    const isWhatsappType = includes(whatsAppType, toUpper(get(bot, "type", "")));

    const [maskedSelected, setMaskedSelected] = useState(!isEmpty(properties?.bubbleMaskType));

    useEffect(() => {
        setIsChecked(has(properties, "path"));
        setIsCheckedButton(get(properties, "renderAsButtons", false));
    }, []);

    function handleChange({ target }) {
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        dispatch(
            updateBubble({
                ...props.bubble,
                properties: {
                    ...properties,
                    [name]: `${value}`,
                },
                action: props.bubble.action === NEW ? NEW : UPDATE,
            })
        );
    }

    // Handle input properties
    function handleInputChange({ target }) {
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        dispatch(
            updateInput({
                ...props.input,
                properties: {
                    ...inputProperties,
                    [name]: `${value}`,
                },
                action: props.input.action === NEW ? NEW : UPDATE,
            })
        );
    }

    function handleEndConversation({ target }) {
        const value = target.value === "true" ? true : false;
        const name = target.name;
        dispatch(
            updateBubble({
                ...props.bubble,
                properties: {
                    ...properties,
                    [name]: value,
                },
                action: props.bubble.action === NEW ? NEW : UPDATE,
            })
        );
    }

    function handleSandboxAction(evt) {
        const value = evt.value;
        const name = evt.name;
        dispatch(
            updateSandbox({
                ...props.sandbox,
                [name]: `${value}`,
                action: props.sandbox.action === NEW ? NEW : UPDATE,
            })
        );
    }

    /**
     * Handle checkbox clean path
     */
    function handleCheckbox({ target }) {
        const value = target.checked;
        setIsChecked(value);

        const bubble = get(props, "bubble");
        const properties = get(bubble, "properties") || {};
        const action = get(props, "bubble.action") === NEW ? NEW : UPDATE;

        const updatedBubbles = {
            ...bubble,
            properties: value ? { ...properties, path: "" } : omit(properties, ["path"]),
            action,
        };

        dispatch(updateBubble(updatedBubbles));
    }

    function handleRenderButtons({ target }) {
        const value = target.checked;
        setIsCheckedButton(value);

        const properties = props.bubble.properties || {};
        const updatedBubbles = {
            ...props.bubble,
            properties: value ? { ...properties, renderAsButtons: true } : omit(properties, ["renderAsButtons"]),
            action: props.bubble.action === NEW ? NEW : UPDATE,
        };

        dispatch(updateBubble(updatedBubbles));
    }

    function handleMask({ target }) {
        const value = target.value;
        const name = target.name;

        const properties = props.bubble.properties || {};
        const updatedBubbles = {
            ...props.bubble,
            properties: {
                ...properties,
                [name]: value,
            },
            action: props.bubble.action === NEW ? NEW : UPDATE,
        };

        dispatch(updateBubble(updatedBubbles));
    }

    const handleMaskSelect = (mask) => {
        dispatch(
            updateBubble({
                ...props.bubble,
                properties: {
                    ...properties,
                    bubbleMaskType: mask.id,
                },
                action: props.bubble.action === NEW ? NEW : UPDATE,
            })
        );
    };

    switch (type) {
        case "input":
            return <BubbleInputSettings handleChange={handleInputChange} properties={inputProperties} channel={channel} />;
        case "sandbox":
            return (
                <div className="grid gap-4 pb-4 text-sm font-semibold text-gray-400 text-opacity-75">
                    <label className="flex flex-col">{t("storeAction?")}</label>
                    <FormCombobox
                        options={sandboxOptions}
                        value={sandboxOptions.find((sandboxOption) => sandboxOption.value === get(sandbox, "storeAction", first(sandboxOptions)))}
                        handleChange={(evt) => {
                            handleSandboxAction({ ...evt, name: "storeAction" });
                        }}
                        name={t("storeAction")}
                        background={"#fff"}
                        hasCleanFilter={false}
                    />
                </div>
            );
        default:
            return (
                <React.Fragment>
                    <div className="flex flex-col space-y-5 pb-4">
                        {toUpper(get(props, "bubble.type", "")) === "TEXT" && isWhatsappType && (
                            <div className="flex items-center">
                                <label className={"flex w-fit pr-1 text-left font-sans text-15 leading-tight text-gray-400"}>
                                    <Checkbox
                                        className="flex"
                                        type="checkbox"
                                        onChange={handleRenderButtons}
                                        checked={isCheckedButton}
                                        name="renderAsButtons"
                                    />
                                    <span className="mr-2 w-52 select-none">{t("bubbles.¿Renderizar botones?")}</span>
                                </label>
                            </div>
                        )}
                        <div className="grid items-center">
                            <label className="flex w-fit pr-1 text-left font-sans text-15 leading-tight text-gray-400">
                                <Checkbox
                                    className="flex"
                                    type="checkbox"
                                    onChange={handleCheckbox}
                                    checked={isChecked}
                                    name={BUBBLES_SETTINGS_CHECKBOX_NAMES.CACHE_DATA_PATH}
                                />
                                <p className="w-52 select-none whitespace-nowrap">
                                    {t("bubbles.¿Usar userCache?")}
                                    <span className="font-medium"> {t("userCache")}</span>
                                </p>
                            </label>
                            <CustomTransition show={isChecked}>
                                <div className="mt-3 grid w-full font-semibold">
                                    <Label className="[&_label]:text-xs" name={t("bubbles.Ruta")} />
                                    <input
                                        className="form-input"
                                        type="text"
                                        defaultValue={get(properties, "path", "")}
                                        onChange={handleChange}
                                        placeholder={`${t("ej")} results.data`}
                                        name={BUBBLES_SETTINGS_CHECKBOX_NAMES.CACHE_DATA_PATH}
                                    />
                                </div>
                            </CustomTransition>
                        </div>
                        {type === BubbleTypes.QUICK_REPLY && <OneTimeUseButtons properties={properties} bubble={props.bubble} />}
                        {type === BubbleTypes.TEXT && (
                            <MaskSettings
                                properties={properties}
                                bubble={props.bubble}
                                handleMask={handleMask}
                                handleMaskSelect={handleMaskSelect}
                                maskedSelected={maskedSelected}
                                setMaskedSelected={setMaskedSelected}
                            />
                        )}
                    </div>

                    {(channel === "Alexa" || channel === "Google") && type === "text" && (
                        <div className="mb-4 flex items-center">
                            <Label name={t("Cierre de Conversación")} />
                            <Select
                                defaultValue={properties.endConversation}
                                onChange={handleEndConversation}
                                name="endConversation"
                                options={endConversationOptions}
                                placeHolder={t("Seleccione...")}
                                className="relative inline-block w-full flex-1"
                            />
                        </div>
                    )}
                </React.Fragment>
            );
    }
};

export default BubbleSettings;
